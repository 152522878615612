import React from "react"
import {motion, useAnimation} from "framer-motion"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {generator, pathRandomizer} from '../helper/buttonGenerator'
import sanitizeHtml from "sanitize-html";
import sanitizeOptions from "../helper/sanitizeOptions";
import {normalizePath} from "../utils/get-url-path";
import addGoogleCaptchaLink from "../helper/addGoogleCaptchaLink";
import * as constants from "../helper/constants";
import useWindowSize from "../hooks/useWindowSize";

let circle = generator({size: 500, edges: 13, growth: 10});
let {paths, scale} = pathRandomizer(circle, 30, 13, 9.5);

const variants = {
    hover: {
        d: paths,
        scale,
        transition: {duration: 10, yoyo: Infinity, ease: "linear"}
    },
    rest: {
        d: circle,
        scale: 1,
        transition: {duration: 1}
    }
};

const MageButton = ({color = '#EC5C2B', size = 228, text, textColor = '#ffffff', url = '#', clickEvent}) => {
    const sanitizeOpt = sanitizeOptions();
    const controls = useAnimation();
    const emptyLinksPattern = new RegExp('^#');
    emptyLinksPattern.test(url);
    const clickEventCommon = () => {
        !!(typeof clickEvent === 'function') && clickEvent();
        addGoogleCaptchaLink();
    };

    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.MOBILE_WIDTH;

    return (
        <div className={'mage-button'}>
            <AniLink
                className={'blog-item-link'}
                paintDrip
                duration={1}
                hex={color}
                to={emptyLinksPattern.test(url) ? url : normalizePath(url)}
                onClick={clickEventCommon}>
                {!isMobile ?
                    <motion.div
                        width={size + 50} height={size + 50}
                        className={'link-wrapper'}
                        whileHover="hover" animate={controls}
                        layout>
                        <svg width={size + 50} height={size + 50} x="0" y="0" viewBox={`0 0 500 500`}>
                            <motion.path
                                style={{cursor: 'pointer'}}
                                initial="rest"
                                variants={variants}
                                d={circle}
                                begin="begin"
                                fill={color}
                            />
                            <foreignObject
                                style={{'pointerEvents': 'none'}}
                                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
                            </foreignObject>
                        </svg>
                        <span className={'link-content'}
                              style={{color: textColor}}
                              dangerouslySetInnerHTML={{__html: sanitizeHtml(text, sanitizeOpt.title)}}/>
                    </motion.div> :
                    <span className={'mage-button-mobile'}
                          style={{backgroundColor: color, width: size + 50, height: size + 50}}>
                        <span className={'link-content'}
                              style={{color: textColor}}
                              dangerouslySetInnerHTML={{__html: sanitizeHtml(text, sanitizeOpt.title)}}/>
                    </span>}
            </AniLink>
        </div>
    )
}

export default MageButton;